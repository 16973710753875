<template>
  <v-progress-circular indeterminate color="primary" class="mx-3">
  </v-progress-circular>
</template>

<script>
export default {
  name: 'LoadingCircle',
};
</script>
